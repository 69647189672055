/* eslint-disable react/prop-types */
import React from 'react';
// import { Link } from 'react-router-dom';
// import ButtonSearch from '../../assets/images/buttons/buttongreen-search.svg';
import { FormSelect } from '../../components/FormRef/Form';
import MiniSearch from '../../assets/images/buttons/button-mini-search.svg';
import MiniFilter from '../../assets/images/buttons/button-mini-filter.svg';
import { FiFilter } from 'react-icons/fi';
import { FiXSquare } from 'react-icons/fi';

import styled from 'styled-components';

//**Components */
import Content from '../../components/Content';
import useList from './useList';
import { toast } from 'react-toastify';

import { Group, Flex, ButtonRow } from '../../components/Table';

export default function List() {  
  const { loading, TableRender, PaginateRender, setConditions, setStage, setOrderBy} = useList('/order-service', 20);
  const [inputSearch, setInputSearch] = React.useState('');  
  const [orderBy] = React.useState([{ name: 'ID', value: 'id' },
    { name: 'Usuário', value: 'user_id' },
    { name: 'Regiao', value: 'user_region' },
    { name: 'Evento', value: 'event_place' },
    { name: 'Data inicial', value: 'start_date' },
    { name: 'Data final', value: 'end_date' },
  ])

  const cbSetConditions = React.useCallback(
    (value) => {
      setConditions(value);
      setStage(0)
    },
    [setConditions, setStage],
  );

  const handleFilter = React.useCallback(() => {
    const filter = document.getElementById('filter');
    filter.classList.toggle('open');
  }, []);

  const handleSetFilter = React.useCallback(() => {
    const condition = document.querySelector(".filter-inputs input[name='condition']:checked");
    const value = document.getElementById('filter-search').value;
   
    if(condition === null) {
      toast.error("Selecione o tipo de filtro!");
      return false;
    }
    cbSetConditions(`${condition.value}${value}`);
  }, [cbSetConditions]);

  const cbHandleChangeState = React.useCallback((e) => {
    setInputSearch(e.target.value);
  }, []);

  const ObHandleChangeState = React.useCallback((e) => { 
    setOrderBy(e.target.value)
  }, []);

  const handleSearch = React.useCallback(() => {
    // const search = document.getElementById("search");
    // cbSetConditions(`&name=${inputSearch}`);    
    cbSetConditions(`&event_place=${inputSearch}`);
  }, [inputSearch]);

  if (loading) return <div></div>;

  return (
    <Content>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }}>
        <div>
          <Container className="height-auto">
            <Flex className="shadow">
              <Group
                id="search"
                className="col-3"
                text="Buscar Local do Evento.."
                type="text"
                value={inputSearch}
                onChange={cbHandleChangeState}
              />
              <FormSelect 
                label="Ordenar por"
                options={orderBy} 
                col={2}
                md={4}
                lg={2}
                name="user_region"
                // firstText="Selecione..."
                onChange={ObHandleChangeState}
              />
              <ButtonRow className="mini" src={MiniSearch} onClick={handleSearch} />
              <ButtonRow className="mini" src={MiniFilter} onClick={handleFilter} />
            </Flex>
          </Container>
        </div>
        <div style={{ flex: 1, overflow: 'auto', scrollbarWidth: 'thin', padding: '0.5rem' }}>
          <TableRender />
        </div>

        <PaginateRender />
        <Filter>
          <header>
            <span>
              <FiFilter size={22} /> Filtros
            </span>
            <FiXSquare size={30} onClick={handleFilter} />
          </header>
          <main className="filter-inputs">    
            <label>
              <input type="radio" name="condition" value="&codigo=" />
              Filtrar pela OS..
            </label>        
            <label>
              <input type="radio" name="condition" value="&event_place=" />
              Filtrar Local do evento..
            </label>
            <label>
              <input type="radio" name="condition" value="&event_name=" />
              Filtrar Ação/Metodo/Evento..
            </label>
            <label>
              <input type="radio" name="condition" value="&status=" />
              Filtrar Status..
            </label>
            <label className="column">
              Buscar por..
              <input id="filter-search" type="text" placeholder="Digite..." name="search" />
            </label>
              <button onClick={handleSetFilter} style={{ backgroundColor: '#1c3faa', color: '#fff' }}>
              <FiFilter size={22} />
              <span>Aplicar filtro</span>
            </button>
          </main>
        </Filter>
      </div>
    </Content>
  );
}

const ContainerStyle = ({ className, children }) => <div className={className}>{children}</div>;

const Container = styled(ContainerStyle)`
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  overflow-y: auto;
  scrollbar-width: thin;
  animation-name: moveInRight;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;

  &.height-auto {
    height: auto;
    overflow: auto;
  }
`;

const Filter = styled(({ children, ...props }) => (
  <div id="filter" className={props.className} onClick={props.onClick}>
    {children}
  </div>
))`
  position: absolute;
  top: 8px;
  bottom: 8px;
  width: 22rem;
  background-color: #fff;
  box-shadow: -1px 2px 10px rgba(0, 0, 100, 0.3);
  border-radius: 8px;
  right: -30rem;
  transition: right 0.4s ease-in-out;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: #7f808a;
    color: #fff;
    margin: 5px;
    border-radius: 4px;
    font-size: 1.2rem;
    user-select: none;
    & span {
      display: flex;
      align-items: center;
    }
    & svg {
      margin-right: 1rem;
    }
  }

  main {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px;

    & label {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      padding: 1rem;
      cursor: pointer;
      font-size: 0.9rem;
      user-select: none;

      &.column {
        flex-direction: column;
        align-items: flex-start;
      }

      & input[type='text'] {
        width: 100%;
        height: 2.5rem;
        border-radius: 4px;
        border: 1px solid #cecece;
        padding: 0 1rem;
        font-size: 1.1rem;
        font-family: inherit;
      }

      & input[type='radio'] {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
      }

      & span {
        margin-left: 5px;
      }
    }

    & button {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      padding: 1rem;
      cursor: pointer;
      border: none;
      border-radius: 4px;
      font-size: 1rem;
      font-weight: 500;
      color: #959595;

      & span {
        flex: 1;
      }
    }
  }

  &.open {
    right: 8px;
  }
`;
